import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { EActionType, IAction } from '../embroidery';
import { ILiquidPixelImage } from './preview';
import styles from './style.css';

interface IEmbroideryItemProps {
    item: any;
    setAction: React.Dispatch<React.SetStateAction<IAction>>;
}

export const EmbroideryItem: React.FC<IEmbroideryItemProps> = ({ item, setAction }) => {
    const t = usePhraseTranslater();
    // For existing quote items preview images will not be available. in that case we will not show preview button.
    const isEnablePreview = item?.liquid_pixel_images?.length > 0;
    const faceLpImage: ILiquidPixelImage|undefined = item?.liquid_pixel_images?.find(
        (lpImage: ILiquidPixelImage) => lpImage.face === 'front',
    );
    const imageUrl: string|undefined = faceLpImage?.regular ||
        item?.configured_variant?.thumbnail?.url ||
        item?.product?.thumbnail?.url;
    return (
        <div className={styles.embroideryItemContainer}>
            <div className={styles.embroideryItem}>
                <div className={styles.embroideryItemHeader}>
                    <span className={styles.embroiderySku}>{item.product.sku}</span>
                    <span className={styles.embroideryQuantity}>{t('QTY %1', item.quantity)}</span>
                </div>
                <div className={styles.embroideryItemDetails}>
                    <img className={styles.embroideryImg} src={imageUrl} alt={item.product.name} />
                    <div className={styles.trash}>
                        <button
                            type="button"
                            className={styles.embroideryButton}
                            onClick={() => setAction({
                                type: EActionType.SHOW_DELETE,
                                data: {
                                    id: item.id,
                                },
                            })}
                        >
                            <i className="fa-solid fa-trash-can" />
                        </button>
                    </div>
                    <div className={styles.details}>
                        <button
                            type="button"
                            className={styles.embroideryButton}
                            onClick={() => setAction({
                                type: EActionType.SHOW_DETAILS,
                                data: {
                                    id: item.id,
                                },
                            })}
                        >
                            {t('details')}
                        </button>
                    </div>
                    {isEnablePreview && (
                        <div className={styles.zoom}>
                            <button
                                type="button"
                                className={styles.embroideryButton}
                                onClick={() => setAction({
                                    type: EActionType.SHOW_PREVIEW,
                                    data: {
                                        id: item.id,
                                    },
                                })}
                            >
                                <i className="fa-solid fa-magnifying-glass-plus" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
