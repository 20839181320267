import React, {
    useRef,
    useState,
    useEffect,
    RefObject,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import ReCAPTCHA from 'react-google-recaptcha';
import { PLACE_ORDER } from 'graphql/cart/place-order';
import { CREATE_CUSTOMER } from 'graphql/customer/mutations';
import { ShowLoader } from 'ui/component/show-loader';
import {
    cartIdVar,
    cartVar,
    configVar,
    customerPasswordVar,
    isGuestVar,
    termsAgreedVar,
    termsForPlaceOrderVar,
    IAcceptedTerm,
    stepsCompletedVar,
    embroideryAgreedVar,
    cartEmbroideryVar,
    isPaymentMethodSetVar,
    shouldTryPlaceOrderVar,
    shouldScrollToLoaderVar,
    placeOrderErrorVar,
    placeOrderInfoVar,
    placeOrderLoadingVar,
    orderPlacedVar,
    SESSION_KEY,
    paymentMethodVariablesVar,
    validatePaymentMethodVar,
    purchaseOrderNumberVar,
    clearSessionStorage,
} from 'ui/page/checkout-page/checkout-state';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { getSessionStorageData, removeSessionStorageData, setSessionStorageData } from 'ui/util/session-storage';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { TermsInputs } from './terms/terms-inputs';
import { TermsLinks } from './terms/terms-links';
import { OrderComments } from './order-comments';
import { PurchaseOrder } from './purchase-order';
import styles from './style.css';

interface IPlaceOrderProps {
    beforePlaceOrder: () => any;
    afterPlaceOrder: () => any;
}

export const PlaceOrder = ({ beforePlaceOrder, afterPlaceOrder }: IPlaceOrderProps) => {
    const [isAgreedOnLoad, setIsAgreedOnLoad] = useState(true);
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const t = usePhraseTranslater();
    const loading = useReactiveVar(placeOrderLoadingVar);
    const info = useReactiveVar(placeOrderInfoVar);
    const checkoutSubmitLoader: RefObject<HTMLDivElement> = React.createRef();
    const isGuest = useReactiveVar(isGuestVar);
    const error = useReactiveVar(placeOrderErrorVar);
    const customerPassword = useReactiveVar(customerPasswordVar);
    const cartId = useReactiveVar(cartIdVar);
    const cart = useReactiveVar(cartVar);
    const termsAgreed = useReactiveVar(termsAgreedVar);
    const embroideryItems = useReactiveVar(cartEmbroideryVar);
    const isEmbroideryAgreed = useReactiveVar(embroideryAgreedVar);
    const shouldTryPlaceOrder = useReactiveVar(shouldTryPlaceOrderVar);
    const shouldScrollToLoader = useReactiveVar(shouldScrollToLoaderVar);
    const orderPlaced = useReactiveVar(orderPlacedVar);
    const termsAndConditions: IAcceptedTerm[] = useReactiveVar(termsForPlaceOrderVar);
    const purchaseOrderNumber = useReactiveVar(purchaseOrderNumberVar);
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const isPaymentMethodSet = useReactiveVar(isPaymentMethodSetVar);
    const price = cart?.prices?.grand_total ? formatCurrency(cart?.prices?.grand_total) : '';
    const config = useReactiveVar(configVar);
    const paymentOptions = useReactiveVar(paymentMethodVariablesVar);
    const validatePaymentMethod = useReactiveVar(validatePaymentMethodVar);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const showTermsInputs = config?.terms_and_conditions_status === '1';
    const recaptchaType = config?.chefworks_checkout_place_order_recaptcha_type ?? null;

    const hasEmbroidery = !!embroideryItems && embroideryItems.length > 0;
    const embroideryAgreed = hasEmbroidery && isEmbroideryAgreed;

    const [placeOrder] = useMutation(PLACE_ORDER, {
        variables: {
            cartId,
            termsAndConditions,
            ...paymentOptions,
            purchaseOrderNumber,
            embroideryAgreed,
        },
        onCompleted: (response) => {
            const orderNumber = response?.placeOrder?.order?.order_number;
            orderPlacedVar(true);
            clearSessionStorage();
            setSessionStorageData('lastOrderedCart', cartId);
            window.location.replace(`/checkout/success/${orderNumber}`);
        },
        onError: async (error) => {
            placeOrderErrorVar(error.message);
            await afterPlaceOrder();
        },
    });
    const [createCustomer] = useMutation(CREATE_CUSTOMER, {
        onCompleted: () => {
            // TODO: link customer to order, we need to run this on order completion
        },
    });
    const isAgreed: boolean = Object.values(termsAgreed).every(value => value);
    const validateTerms = (): void => {
        setIsAgreedOnLoad(false);
        if (showTermsInputs) {
            if (!isAgreed) {
                placeOrderLoadingVar(false);
                const errorMessage = t('Please agree to the terms and conditions');
                placeOrderErrorVar(errorMessage);
                throw new Error(errorMessage);
            }
        }
    };
    const isCartReadyToSubmit = (): boolean => Boolean(
        stepsCompleted.customer &&
        stepsCompleted.shipping &&
        stepsCompleted.billing &&
        stepsCompleted.embroidery &&
        isPaymentMethodSet,
    );

    const processError = (error: Error): void => {
        const { message } = error;
        if (message.startsWith('Info:')) {
            const infoMessage = message.split(':')[1] ?? '';
            placeOrderInfoVar(infoMessage);
        } else {
            placeOrderLoadingVar(false);
            placeOrderErrorVar(message);
        }
    };

    const handleSubmit = async (recaptchaToken: string|null) => {
        placeOrderErrorVar('');
        placeOrderLoadingVar(true);

        if (!validatePaymentMethod()) {
            placeOrderLoadingVar(false);
            return;
        }

        validateTerms();

        if (typeof beforePlaceOrder === 'function') {
            try {
                await beforePlaceOrder();
            } catch (error) {
                processError(error as Error);
                return;
            }
        }

        if (isGuest && cart && cart.email && customerPassword) {
            let firstname = '';
            let lastname = '';
            if (cart.is_virtual) {
                firstname = cart.billing_address?.firstname;
                lastname = cart.billing_address?.lastname;
            } else {
                firstname = cart?.shipping_addresses[0]?.firstname;
                lastname = cart?.shipping_addresses[0]?.lastname;
            }

            await createCustomer({
                variables: {
                    input: {
                        email: cart.email,
                        firstname,
                        lastname,
                        password: customerPassword,
                        is_subscribed: true,
                    },
                },
            });
        }
        removeSessionStorageData(SESSION_KEY.guestPassword);

        const context = recaptchaToken ? {
            headers: {
                'X-ReCaptcha': recaptchaToken,
            },
        } : {};

        await placeOrder({
            context,
        });
        placeOrderLoadingVar(false);
        paymentMethodVariablesVar({});
    };

    const triggerSubmit = (event: React.FormEvent|null) => {
        if (event) {
            event.preventDefault();
        }
        if (recaptchaRef.current && recaptchaType === 'invisible') {
            recaptchaRef.current.execute();
        } else {
            handleSubmit(recaptchaToken);
        }
    };

    useEffect((): void => {
        /**
         * If this variable is set up to `true`, it means there was a certain logic processed, e.g. setup payment
         * method, additional credentials and there is no need to push `Pay` button again, all the validations were
         * done before. Example: PayPal Express payment method component.
         */
        if (shouldTryPlaceOrder) {
            triggerSubmit(null);
        }
    }, [shouldTryPlaceOrder]);

    const scrollToTheLoaderAction = () => {
        if (checkoutSubmitLoader && checkoutSubmitLoader.current) {
            checkoutSubmitLoader.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            shouldScrollToLoaderVar(false);
        }
    };

    useEffect((): void => {
        /**
         * Scroll into view to the loader
         */
        if (shouldScrollToLoader) {
            scrollToTheLoaderAction();
        }
    }, [shouldScrollToLoader]);

    if (loading) {
        return (
            <div ref={checkoutSubmitLoader} className={styles.checkoutSubmitLoader}>
                <ShowLoader message={info || t('Please wait...')} />
            </div>
        );
    }

    const isReadyToPay: boolean = !loading && isCartReadyToSubmit() && (isAgreedOnLoad || isAgreed);

    const currentPaymentMethod = getSessionStorageData('currentPaymentMethod', true) ?? cart?.selected_payment_method?.code;

    return (
        <>
            <div className={styles.placeOrderContent}>
                <OrderComments />
                <PurchaseOrder />
                {config && !orderPlaced && (
                    <form onSubmit={(event: React.FormEvent) => triggerSubmit(event)} data-test="place-order">
                        <TermsInputs />
                        {config?.recaptcha_sitekey && recaptchaType && (
                            <div className={styles.recaptchaContainer}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={config?.recaptcha_sitekey}
                                    size={recaptchaType === 'recaptcha' ? 'normal' : 'invisible'}
                                    isolated
                                    onChange={(token) => {
                                        setRecaptchaToken(token);
                                        if (recaptchaType === 'invisible') {
                                            handleSubmit(token);
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <button
                            type="submit"
                            className={classes(styles.placeOrder, fStyles.checkoutButton,
                                { [styles.paypalButton]: currentPaymentMethod === 'paypal_express' })}
                            disabled={!isReadyToPay}
                            data-test="place-order-button"
                        >
                            {currentPaymentMethod === 'paypal_express' &&
                                <i className={classes('fab fa-paypal', styles.paypalLogo)} />}
                            {t('Pay %1', price)}
                        </button>
                        {error && <span className={styles.error}>{error}</span>}
                        <TermsLinks />
                    </form>
                )}
            </div>
        </>
    );
};
