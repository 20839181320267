import React, { useEffect, forwardRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { configVar } from 'ui/page/checkout-page/checkout-state';
import { IAddress } from './address-form';

interface IAutoCompleteProps {
    setAddress: (address: (prevAddress: IAddress) => any) => void;
}

interface IAutocomplete {
    new(input: HTMLInputElement | null): IAutocomplete;
    addListener: (event: string, callback: () => void) => void;
    getPlace: () => {
        // eslint-disable-next-line camelcase
        address_components: {
            // eslint-disable-next-line camelcase
            long_name: string;
            // eslint-disable-next-line camelcase
            short_name: string;
            types: string[];
        }[];
    };
}

interface IAddressFieldMapping {
    [key: string]: string;
}

declare global {
    interface Window {
        initMap: () => void;
        google: {
            maps: {
                places: {
                    Autocomplete: IAutocomplete;
                };
            };
        } | undefined;
    }
}

const AutoComplete = forwardRef<HTMLInputElement, IAutoCompleteProps>((props, ref) => {
    const { setAddress } = props;
    const config = useReactiveVar(configVar);
    let autocomplete: IAutocomplete;

    const handlePlaceChanged = () => {
        const place = autocomplete.getPlace();
        const addressFieldMapping: IAddressFieldMapping = {
            street: 'street',
            locality: 'city',
            postal_town: 'city',
            neighborhood: 'city',
            administrative_area_level_1: 'region',
            postal_code: 'postcode',
            country: 'countryCode',
        };
        const newAddress: IAddressFieldMapping = {};
        let streetNumber = '';
        let route = '';
        let subPremise = '';
        place.address_components.forEach((component) => {
            component.types.forEach((type) => {
                if (addressFieldMapping[type]) {
                    const value =
                    (type === 'administrative_area_level_1' ||
                        type === 'country')
                        ? component.short_name : component.long_name;
                    newAddress[addressFieldMapping[type]] = value;
                }
                if (type === 'street_number') {
                    streetNumber = component.short_name;
                }
                if (type === 'route') {
                    route = component.short_name;
                }
                if (type === 'subpremise') {
                    subPremise = component.short_name;
                }
            });
        });
        newAddress.street = `${streetNumber} ${route} ${subPremise}`.trim();
        setAddress(prevAddress => ({ ...prevAddress, ...newAddress }));
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const initAutocomplete = () => {
            if (window.google && window.google.maps && window.google.maps.places && ref && 'current' in ref && !autocomplete) {
                autocomplete = new window.google.maps.places.Autocomplete(ref.current);
                autocomplete.addListener('place_changed', handlePlaceChanged);
            }
        };

        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${config?.google_address_completion_api_key}&libraries=places&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            window.initMap = initAutocomplete;
            return () => {
                document.body.removeChild(script);
            };
        }
        initAutocomplete();
    }, [config?.google_address_completion_api_key, ref]);

    return <React.Fragment />;
});

export default AutoComplete;
