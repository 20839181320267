import React from 'react';
import { Divider } from 'ui/component/checkout/components/divider';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { ILiquidPixelImage } from '../preview';
import { ProductInfoLine } from './product-info-line';
import styles from './style.css';

interface IProductInfoProps {
    item: ICartItemProps;
}

export const ProductInfo: React.FC<IProductInfoProps> = ({ item }) => {
    const productColor = item?.configurable_options?.filter(option => option.option_code === 'color')[0]?.value_label;
    const productSize = item?.configurable_options?.filter(option => option.option_code === 'size')[0]?.value_label;

    const faceLpImage: ILiquidPixelImage|undefined = item?.liquid_pixel_images?.find(
        (lpImage: ILiquidPixelImage) => lpImage.face === 'front',
    );
    const imageUrl: string|undefined = faceLpImage?.regular ||
        item?.configured_variant?.thumbnail?.url ||
        item?.product?.thumbnail?.url;
    return (
        <div className={styles.productDetailsWrapper}>
            <div className={styles.productDetails}>
                <div className={styles.productInfo}>
                    <ProductInfoLine label="" value={item.product.name} className={styles.productName} />
                    <ProductInfoLine label="item" value={item.product.sku} />
                    <ProductInfoLine label="color" value={productColor ?? ''} />
                    <ProductInfoLine label="size" value={productSize ?? ''} />
                    <ProductInfoLine label="qty" value={String(item.quantity)} />
                </div>
                <div className={styles.productImage}>
                    <img src={imageUrl} alt={item.product.name} />
                </div>
            </div>
            <Divider />
        </div>
    );
};
