import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ScrollOnHover } from 'ui/component/scroll-on-hover';
import { cartEmbroideryVar, embroideryAgreedVar, stepsCompletedVar } from 'ui/page/checkout-page/checkout-state';
import { setSessionStorageData } from 'ui/util/session-storage';
import { EmbroideryItem } from './embroidery-items/embroidery-item';
import { Details } from './embroidery-items/details';
import { Delete } from './embroidery-items/delete';
import { Preview } from './embroidery-items/preview';
import styles from './style.css';

export enum EActionType {
    SHOW_LIST = 'show_list',
    SHOW_DETAILS = 'show_details',
    SHOW_DELETE = 'show_delete',
    SHOW_PREVIEW = 'show_preview'
}

export interface IAction {
    type: EActionType;
    data: {
        id?: number;
    };
}

export const Embroidery = () => {
    const t = usePhraseTranslater();
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const isDesktop = useMediaQuery({ query: '(min-width: 1041px)' });
    const embroideryItems = useReactiveVar(cartEmbroideryVar);
    const embroideryAgreed = useReactiveVar(embroideryAgreedVar);
    const getDefaultAction: IAction = {
        type: EActionType.SHOW_LIST,
        data: {
            id: 0,
        },
    };
    const [action, setAction] = useState<IAction>(getDefaultAction);
    const getEmbroideryItemById = (id: number) => {
        if (!embroideryItems) return null;
        return embroideryItems.find(item => item.id === id);
    };
    const approveEmbroidery = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        embroideryAgreedVar(isChecked);
        setSessionStorageData('embroideryAgreed', isChecked.toString());
        stepsCompletedVar({ ...stepsCompleted, embroidery: true });
    };
    const showNote = Boolean(isDesktop
        ? (embroideryItems && embroideryItems.length > 3)
        : (embroideryItems && embroideryItems.length > 2));
    const EmbroideryApproval: React.FC = () => (
        <div className={styles.embroideryApproval}>
            <input
                type="checkbox"
                id="approveEmbroidery"
                name="approveEmbroidery"
                checked={embroideryAgreed}
                onChange={approveEmbroidery}
            />
            <label htmlFor="approveEmbroidery">{t('I approve all embroidery selections')}</label>
        </div>
    );

    return (
        <>
            {action.type && action.type === EActionType.SHOW_LIST && (
                <>
                    <ScrollOnHover>
                        <div className={styles.embroideryGrid}>
                            {embroideryItems && embroideryItems.map(item => (
                                <EmbroideryItem
                                    key={item.id}
                                    item={item}
                                    setAction={setAction}
                                />
                            ))}
                        </div>
                    </ScrollOnHover>
                    <div className={styles.embroideryFooter}>
                        {showNote && (
                            <div className={styles.embroideryNote}>
                                {t('scroll to see more')}
                                <i className="fa-solid fa-arrow-down-long" />
                            </div>
                        )}
                        <EmbroideryApproval />
                    </div>
                </>
            )}
            {
                action.type && action.type === EActionType.SHOW_DETAILS && action.data.id && (
                    <>
                        <Details
                            item={getEmbroideryItemById(action.data.id)}
                            handleClose={() => setAction(getDefaultAction)}
                        />
                        <div className={styles.embroideryFooter}>
                            <EmbroideryApproval />
                        </div>
                    </>
                )
            }
            {
                action.type && action.type === EActionType.SHOW_DELETE && action.data.id && (
                    <Delete
                        id={action.data.id}
                        handleClose={() => setAction(getDefaultAction)}
                    />
                )
            }
            {
                action.type && action.type === EActionType.SHOW_PREVIEW && action.data.id && (
                    <Preview
                        item={getEmbroideryItemById(action.data.id)}
                        handleClose={() => setAction(getDefaultAction)}
                    />
                )
            }
        </>
    );
};
