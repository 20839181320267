import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isArray, isString } from 'lodash';
import { availableCountriesVar, configVar } from 'ui/page/checkout-page/checkout-state';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { AddressFormFieldText } from './address-form-field-text';
import { AddressFormFieldSelect } from './address-form-field-select';
import { AddressFormFieldCheckbox } from './address-form-field-checkbox';
import { AddressFormButton } from './address-form-button';
import { validateAddressForm, IAddressFormErrors, getFormValidationErrors } from './address-form-validation';
import styles from './style.css';

export interface IAddress {
    firstname: string;
    lastname: string;
    company: string;
    street: string[];
    city: string;
    region: string;
    postcode: string;
    countryCode: string;
    telephone: string;
    saveInAddressBook: boolean;
}

interface IAddressFormProps {
    address: IAddress;
    name: string;
    handleSubmit: any;
    onChange: any;
    loading: boolean;
    setAddress: any;
    allowSave?: boolean;
    disableInputs?: boolean;
}

interface IRegion {
    name: string;
    code: string;
}

interface ICountry {
    // eslint-disable-next-line camelcase
    full_name_english: string;
    id: string;
    // eslint-disable-next-line camelcase
    available_regions: IRegion[];
}

export const AddressForm: React.FC<IAddressFormProps> = ({
    address,
    name,
    handleSubmit,
    onChange,
    loading,
    setAddress,
    allowSave,
    disableInputs,
}) => {
    /*
     * Validation error handling
     */
    const [errors, setErrors] = useState(getFormValidationErrors() as IAddressFormErrors);
    const [showEnterManually, setShowEnterManually] = useState(false);
    const handleValidation = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formFields = { ...address };
        const { formIsValid, formValidationErrors } = validateAddressForm(formFields, name);
        setErrors(formValidationErrors);
        if (formIsValid) handleSubmit(event);
    };
    /**
     * Country and state/region handling
     */
    const config = useReactiveVar(configVar);
    const [countryOptions, setCountryOptions] = useState([{ name: 'Loading...', value: '', selected: true }]);
    const [stateOptions, setStateOptions] = useState([{ name: 'Select your country', value: '' }]);
    const preSelectedCountry =
        isString(address.countryCode) && address.countryCode !== ''
            ? address.countryCode : config?.general_country_default;
    const [selectedCountry, setCountry] = useState(preSelectedCountry);
    const [textStateField, setTextStateField] = useState(false);
    const updateCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);
        onChange(event); // Call the parent on change event as well
    };
    const countries: ICountry[] | undefined = useReactiveVar(availableCountriesVar);
    /**
     * Update available regions based on selected country
     */
    const updateRegions = () => {
        const currentCountry = selectedCountry && countries ? countries.filter(
            (country: { id: string }) => country.id === selectedCountry,
        )[0] : null;
        let stateOptions: ({ name: string; value: string } | { name: string; value: '' })[] = [];
        if (currentCountry === null) {
            stateOptions = [{ name: 'Select your country', value: '' }];
        } else {
            stateOptions = currentCountry?.available_regions ? currentCountry.available_regions.map(
                (region: { name: string; code: string }) => ({ name: region.name, value: region.code }),
            ) : [];
            if (stateOptions.length) {
                setTextStateField(false); // Use select input
                stateOptions = [{ name: 'Select your state', value: '' }, ...stateOptions];
            } else {
                setTextStateField(true); // Use text input
                stateOptions = [{ name: 'N/A', value: '' }];
            }
        }
        setStateOptions(stateOptions);
    };
    useEffect(() => {
        /**
         * Setup country
         */
        const countryOptions = countries ? countries.map(
            // eslint-disable-next-line camelcase
            (country: { full_name_english: string; id: string }) => (
                { name: country.full_name_english, value: country.id, selected: country.id === selectedCountry }
            ),
        ).sort((a, b) => a.name.localeCompare(b.name))
            : [];
        setCountryOptions(countryOptions);
        /**
         * Set country
         */
        if (selectedCountry) {
            setAddress({ ...address, countryCode: selectedCountry });
        }
        updateRegions();
    }, [countries]);
    /**
     * Update regions whenever selected country changes
     */
    useEffect(() => {
        updateRegions();
    }, [selectedCountry]);
    /**
     * Show manual address field for error from to autocomplete
     */
    useEffect(() => {
        if (!showEnterManually && !disableInputs && (errors.city || errors.region || errors.postcode)) {
            setShowEnterManually(true);
        }
    }, [errors]);
    /*
     Form
     */
    const disabled = loading || countries === undefined;
    const street1 = (isArray(address.street) ? address.street[0] : address.street) || '';
    const location = street1
        ? `${street1} ${address.city} ${address.region} ${address.postcode}` : '';

    return (
        <form id={`${name}-address-form`} onSubmit={handleValidation} className={styles.addressForm}>
            <AddressFormFieldSelect
                label="Country"
                name="countryCode"
                options={countryOptions}
                onChange={updateCountry}
                selectedValue={selectedCountry}
                error={errors.countryCode}
                disabled={disableInputs}
            />
            <AddressFormFieldText label="Company" name="company" value={address.company} onChange={onChange} error={errors.company} disabled={disableInputs} />
            <AddressFormFieldText label="First Name" name="firstname" value={address.firstname} onChange={onChange} error={errors.firstname} disabled={disableInputs} />
            <AddressFormFieldText label="Last Name" name="lastname" value={address.lastname} onChange={onChange} error={errors.lastname} disabled={disableInputs} />
            <AddressFormFieldText label="Phone Number" name="telephone" value={address.telephone} onChange={onChange} error={errors.telephone} disabled={disableInputs} />
            { !showEnterManually && !disableInputs && (
                <>
                    <AddressFormFieldText
                        label="Add&#8204;ress" // Zero width space to stop autofill
                        name="location"
                        value={location}
                        onChange={onChange}
                        error={errors.street1}
                        autocomplete
                        setAddress={setAddress}
                    />
                    <button data-test="enter-address-manually" type="button" onClick={() => setShowEnterManually(true)} className={fStyles.buttonLink}>
                        Enter Address Manually
                    </button>
                </>
            )}
            <div className={((!showEnterManually && !disableInputs) ? styles.hide : '')}>
                <AddressFormFieldText label="Address Line 1" name="street" value={street1} onChange={onChange} error={errors.street1} disabled={disableInputs} />
                <AddressFormFieldText label="City" name="city" value={address.city} onChange={onChange} error={errors.city} disabled={disableInputs} />
                { textStateField && (
                    <AddressFormFieldText label="State" name="region" value={address.region} onChange={onChange} error={errors.region} disabled={disableInputs} />
                )}
                { !textStateField && (
                    <AddressFormFieldSelect label="State" name="region" options={stateOptions} selectedValue={address.region} onChange={onChange} error={errors.region} disabled={disableInputs} />
                )}
                <AddressFormFieldText label="Zip Code" name="postcode" value={address.postcode} onChange={onChange} error={errors.postcode} disabled={disableInputs} />
            </div>
            { allowSave && <AddressFormFieldCheckbox label="Save in address book" name="saveInAddressBook" onChange={onChange} error="" checked={address.saveInAddressBook} /> }
            <AddressFormButton label="Submit" disabled={disabled} />
        </form>
    );
};
