import React, { useState } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { cartIdVar, cartVar, InitializeCheckout } from 'ui/page/checkout-page/checkout-state';
import { useMediaQuery } from 'react-responsive';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { Cart } from 'ui/component/checkout/cart';
import { REMOVE_FROM_CART_MUTATION, UPDATE_CART_MUTATION } from 'graphql/cart/cart';
import { setSessionStorageData } from 'ui/util/session-storage';
import { CheckoutSteps } from './checkout-steps';
import { LoaderContext } from './checkout-loader';
import styles from './style.css';

export const Checkout = () => {
    const cartId = useReactiveVar(cartIdVar);
    const isDesktop = useMediaQuery({ query: '(min-width: 1041px)' });
    const [cartLoading, setCartLoading] = useState(false);
    const [removeFromCart] = useMutation(REMOVE_FROM_CART_MUTATION);
    const [updateCart] = useMutation(UPDATE_CART_MUTATION);
    const setCartItemQty = async (qty: number, item: any) => {
        const cartItemId = String(item.id);
        setCartLoading(true);
        const response = await updateCart({
            variables: {
                input: {
                    cart_id: cartId,
                    cart_items: [{
                        cart_item_id: cartItemId,
                        quantity: Number(qty),
                    }],
                },
            },
            onError: () => {
                // prevent error log
            },
        });
        // TODO: Add loading state
        const { data } = response;
        if (data?.updateCartItems.cart.id) {
            setSessionStorageData('cartId', data.updateCartItems.cart.id);
            cartIdVar(data.updateCartItems.cart.id);
            cartVar(data.updateCartItems.cart);
        }
        setCartLoading(false);
        return true;
    };

    const removeCartItem = async (item) => {
        setCartLoading(true);
        const cartItemId = String(item.id);
        const response = await removeFromCart({
            variables: {
                input: {
                    cart_id: cartId,
                    cart_item_id: cartItemId,
                },
            },
            onError: () => {
                // catch errors
            },
        });
        // TODO: Add loading state
        const { data } = response;
        if (data?.removeItemFromCart.cart.id) {
            // console.log('Cart Id Updated : ', data.removeItemFromCart.cart.id);
            setSessionStorageData('cartId', data.removeItemFromCart.cart.id);
            cartIdVar(data.removeItemFromCart.cart.id);
            cartVar(data.removeItemFromCart.cart);
        }
        setCartLoading(false);
    };
    return (
        <>
            {cartId && (
                <LoaderContext.Provider value={{ cartLoading, setCartLoading }}>
                    <InitializeCheckout props={{ setCartItemQty, removeCartItem }}>
                        <div className={styles.checkoutGridLeft}>
                            <CheckoutSteps />
                        </div>
                        <div className={styles.checkoutGridRight}>
                            {isDesktop && <Cart />}
                        </div>
                    </InitializeCheckout>
                </LoaderContext.Provider>
            )}
            {!cartId && <StandardLoadingImage />}
        </>
    );
};
