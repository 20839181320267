import React from 'react';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import DOMPurify from 'dompurify';
import { RawSpan } from 'ui/component/raw/span';
import styles from './style.css';

export const CartTotal = ({ cartSummaryData }) => {
    const t = usePhraseTranslater();
    // eslint-disable-next-line
    const { grand_total, raw_totals } = cartSummaryData.prices;
    // eslint-disable-next-line
    const rewards_total = raw_totals.filter(item => item.code === 'rewards-total')[0] ?? {};
    const rewardsTotalHtml = rewards_total?.label?.replace(/{{REWARDS}}/g, formatCurrency(rewards_total?.amount));
    return (
        <>
            <div className={styles.total}>
                <span className={classes(styles.label, styles.colon)}>{t('Total')}</span>
                <span className="weglot-exclude">{formatCurrency(grand_total)}</span>
            </div>
            <div className={styles.rewardsTotal}>
                <RawSpan data-test="rewards-total">{DOMPurify.sanitize(rewardsTotalHtml)}</RawSpan>
            </div>
        </>
    );
};
